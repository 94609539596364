<template>
  <div v-loading="queryLoading">
    <el-form
      ref="form"
      :disabled="isDetail"
      :model="form"
      :rules="rules"
      label-width="auto"
      size="small"
    >
      <!--订单信息-->
      <h3>{{ $t('title.orderinfo') }}</h3>
      <el-row :gutter="50" style="margin-left: 20px">
        <!--平台订单号-->
        <el-col :span="10">
          <el-form-item :label="$t('topic.PlatformOrderNumber')">
            <el-input
              v-model.trim="form.orderCode"
              :placeholder="$t('title.Afterentering')"
              clearable
            />
          </el-form-item>
        </el-col>
        <!--平台-->
        <el-col :span="10">
          <el-form-item :label="$t('title.platform')" prop="platFormName">
            <el-input v-model="form.platFormName" />
          </el-form-item>
        </el-col>
        <!--站点-->
        <el-col :span="10">
          <el-form-item :label="$t('title.site')" prop="siteCode">
            <el-input v-model="form.siteCode" />
          </el-form-item>
        </el-col>
        <!--账号-->
        <el-col :span="10">
          <el-form-item :label="$t('title.shopName')" prop="shopCode">
            <el-input v-model="form.shopCode" />
          </el-form-item>
        </el-col>
        <!--买家ID-->
        <el-col :span="10">
          <el-form-item :label="$t('topic.BuyerID')" prop="buyerUsername">
            <el-input v-model="form.buyerUsername" />
          </el-form-item>
        </el-col>
        <!--退货仓-->
        <el-col :span="10">
          <el-form-item :label="$t('topic.ReturnWarehouse')">
            <el-input v-model="form.shipperAddress" />
          </el-form-item>
        </el-col>
        <!--RMA状态-->
        <el-col :span="10">
          <el-form-item :label="$t('topic.RMAstatus')">
            <el-input v-model="form.returnStatusName" />
          </el-form-item>
        </el-col>
        <!--RMA类型-->
        <el-col :span="10">
          <el-form-item :label="$t('topic.RMAType')" prop="returnDetailType">
            <el-radio-group v-model="form.returnDetailType">
              <el-radio :label="0">{{ $t('topic.Refunds') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!--RMA方式-->
        <el-col :span="24">
          <el-form-item :label="$t('topic.RMAWay')" prop="returnType">
            <el-radio-group v-model="form.returnType">
              <el-radio :label="0">{{ $t('topic.System') }}</el-radio>
              <el-radio :label="1">{{ $t('topic.Manual') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!--创建人-->
        <el-col :span="10">
          <el-form-item :label="$t('page.createByName')">
            <el-input v-model="form.returnCreateName" />
          </el-form-item>
        </el-col>
        <!--创建时间-->
        <el-col :span="10">
          <el-form-item :label="$t('page.createTime')">
            <el-input v-model="form.returnCreateTime" />
          </el-form-item>
        </el-col>
        <!--审核人-->
        <el-col :span="10">
          <el-form-item :label="$t('topic.Reviewer')">
            <el-input v-model="form.returnReviewName" />
          </el-form-item>
        </el-col>
        <!--审核时间-->
        <el-col :span="10">
          <el-form-item :label="$t('topic.Reviewtime')">
            <el-input v-model="form.returnReviewTime" />
          </el-form-item>
        </el-col>
        <!--退款人-->
        <el-col :span="10">
          <el-form-item :label="$t('topic.Refunder')">
            <el-input v-model="form.returnPaymentName" />
          </el-form-item>
        </el-col>
        <!--退款时间-->
        <el-col :span="10">
          <el-form-item :label="$t('topic.Refundtime')">
            <el-input v-model="form.returnPaymentTime" />
          </el-form-item>
        </el-col>
      </el-row>
      <!--退款信息-->
      <h3>{{ $t('title.Refundinformation') }}</h3>
      <el-row :gutter="50" style="margin-left: 20px">
        <!--退款类型-->
        <el-col :span="24">
          <el-form-item :label="$t('topic.Typerefund')">
            <el-radio-group v-model="form.refundType">
              <!--同意退货退款-->
              <el-radio :label="0">{{ $t('topic.Agreeandrefund') }}</el-radio>
              <!--全部退款-->
              <el-radio :label="1">{{ $t('topic.Fullrefund') }}</el-radio>
              <!--部分退款-->
              <el-radio :label="2">{{ $t('topic.Partialrefund') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!--产品信息-->
      <h3>{{ $t('title.Productinformation') }}</h3>
      <vxe-table
        :data="form.detailList"
        :expand-config="{expandAll: true}"
        :row-config="{height: 60}"
        :scroll-x="{ enabled: false }"
        :scroll-y="{ enabled: false }"
        :show-overflow="false"
        align="center"
        max-height="400"
        resizable
      >
        <vxe-column type="expand" width="30">
          <template #content="{row}">
            <el-descriptions
              :column="3"
              :content-style="{'text-align':'center'}"
              :label-style="{'text-align':'center', 'font-weight': 600, color: '#606266'}"
              border
              direction="vertical"
            >
              <!--产品费-->
              <el-descriptions-item v-if="row.itemPrice">
                {{ $t('topic.Productfee') }}
              </el-descriptions-item>
              <!--订单金额-->
              <el-descriptions-item v-if="row.itemPrice" :label="$t('title.orderamount')">
                <!--订单金额 × 产品费-->
                {{ row.itemPrice }}
              </el-descriptions-item>
              <!--订单退款-->
              <el-descriptions-item v-if="row.itemPrice" :label="$t('topic.Orderrefund')">
                <!--订单退款 × 产品费-->
                <el-input v-model="row.myItemPrice" clearable>
                  <template #prepend>
                    $
                  </template>
                </el-input>
              </el-descriptions-item>
              <!--产品税费-->
              <el-descriptions-item v-if="row.itemTax" :label-style="{display:'none'}">
                {{ $t('topic.Producttaxes') }}
              </el-descriptions-item>
              <el-descriptions-item v-if="row.itemTax" :label-style="{display:'none'}">
                <!--订单金额 × 产品税费-->
                {{ row.itemTax }}
              </el-descriptions-item>
              <el-descriptions-item v-if="row.itemTax" :label-style="{display:'none'}">
                <!--订单退款 × 产品税费-->
                <el-input v-model="row.myItemTax" clearable>
                  <template #prepend>
                    $
                  </template>
                </el-input>
              </el-descriptions-item>
              <!--额外运费-->
              <el-descriptions-item v-if="row.shippingPrice" :label-style="{display:'none'}">
                {{ $t('topic.Extrashipping') }}
              </el-descriptions-item>
              <el-descriptions-item v-if="row.shippingPrice" :label-style="{display:'none'}">
                <!--订单金额 × 额外运费-->
                {{ row.shippingPrice }}
              </el-descriptions-item>
              <el-descriptions-item v-if="row.shippingPrice" :label-style="{display:'none'}">
                <!--订单退款 × 额外运费-->
                <el-input v-model="row.myShippingPrice" clearable>
                  <template #prepend>
                    $
                  </template>
                </el-input>
              </el-descriptions-item>
              <!--额外退费/收费-->
              <el-descriptions-item v-if="row.itemPrice" :label="$t('title.Additionalrefund')">
                <!--额外退费/收费 × 额外退费-->
                {{ $t('topic.Additionalrefunds') }}
              </el-descriptions-item>
              <!--金额-->
              <el-descriptions-item v-if="row.itemPrice" :label="$t('title.Amount')" :span="2">
                <!--额外退费/收费 × 额外退费-->
                <el-input v-model="row.myOtherRefund" clearable>
                  <template #prepend>
                    $
                  </template>
                </el-input>
              </el-descriptions-item>
              <!--总计-->
              <el-descriptions-item
                :content-style="{'font-weight': 600, color: '#606266', 'text-align': 'center'}"
                :label-style="{display:'none'}"
              >
                {{ $t('title.Totalrefund') }}
              </el-descriptions-item>
              <el-descriptions-item
                :content-style="{'font-weight': 600, color: '#606266', 'text-align': 'center'}"
                :label-style="{display:'none'}"
              >
                <!--订单金额 × 总计-->
                {{ row.myRefundAmount }}
              </el-descriptions-item>
              <el-descriptions-item
                :content-style="{'font-weight': 600, color: '#606266', 'text-align': 'center'}"
                :label-style="{display:'none'}"
              >
                <!--订单退款 × 总计-->
                {{ row.refundAmount }}
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </vxe-column>
        <!--退款信息-->
        <vxe-column :title="$t('title.Refundinformation')">
          <template #default="{row}">
            <div style="display:flex;align-items: center;justify-content: center">
              <el-image
                :src="row.mainImage"
                class="mr-3"
                style="width: 50px; height: 50px;"
              />
              <span>{{ row.sku }}</span>
            </div>
          </template>
        </vxe-column>
        <!--退款数量-->
        <vxe-column :title="$t('topic.Numberofrefunds')" field="returnNumber" />
        <!--退款原因-->
        <vxe-column :title="$t('topic.reasonforreturn')">
          <template #default="{row}">
            <el-select
              v-model="row.returnReasonCodeCN"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              filterable
            >
              <el-option
                v-for="item in returnReasonOptions"
                :key="item.returnReasonCodeEN"
                :label="language === 'en-US' ? item.returnReasonCodeEN : item.returnReasonCodeCN"
                :value="item.returnReasonCodeCN"
              />
            </el-select>
          </template>
        </vxe-column>
        <!--跟踪号-->
        <vxe-column :title="$t('title.Trackingforreturn')" field="trackingId" />
      </vxe-table>
      <el-row :gutter="50" style="margin-left: 20px;margin-top: 18px">
        <!--买家留言-->
        <el-col :span="15">
          <el-form-item :label="$t('title.Buyerpessage')">
            <el-input
              v-model="form.sellComments"
              :rows="2"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <!--系统备注-->
        <el-col :span="15">
          <el-form-item :label="$t('title.stytemNote')">
            <el-input
              v-model="form.remark"
              :rows="2"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <!--买家图片-->
        <el-col :span="24">
          <el-form-item :label="$t('title.Buyerpicture')">
            <div v-for="(item, index) in sellerFileList" :key="item + index">
              <el-image
                :preview-src-list="sellerFileList"
                :src="item"
                fit="contain"
                lazy
                style="width: 60px;height: 60px"
              />
            </div>
          </el-form-item>
        </el-col>
        <!--卖家图片-->
        <el-col :span="24">
          <el-form-item :label="$t('title.Attachpicture')">
            <div v-for="(item, index) in brcFileList" :key="item + index">
              <el-image
                :preview-src-list="brcFileList"
                :src="item"
                fit="contain"
                lazy
                style="width: 60px;height: 60px"
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="width: 100%;text-align: center">
      <el-button @click="$router.push('/rma-manage/shein-return-manage')">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getAllReasonCode, getDetailReturnsInfoById } from '@/api/omsorder'
import Cookies from 'js-cookie'

export default {
  name: 'SheInReturnInfo',
  data() {
    return {
      queryLoading: false,
      form: {
        detailList: [],
        orderCode: '',
        siteCode: '',
        shopCode: '',
        platFormName: '',
        buyerUsername: '',
        shipperAddress: '',
        returnStatusName: '',
        returnCreateName: '',
        returnReviewName: '',
        returnReviewTime: '',
        returnPaymentName: '',
        returnPaymentTime: '',
        returnCreateTime: '',
        returnDetailType: '',
        returnType: '',
        refundType: ''
      },
      rules: {},
      sellerFileList: [],
      brcFileList: [],
      language: '',
      returnReasonOptions: []
    }
  },
  computed: {
    isDetail() {
      return !!this.$route.query.id
    }
  },
  created() {
    const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    const erpLang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
    this.language = erpLang || lang
    this.getReturnInfo()
  },
  methods: {
    async getReasonOptions(platform) {
      const { datas } = await getAllReasonCode(platform)
      this.returnReasonOptions = datas
    },
    async getReturnInfo() {
      const { id } = this.$route.query
      if (id) {
        this.queryLoading = true
        const { datas } = await getDetailReturnsInfoById(id).finally(() => {
          this.queryLoading = false
        })
        datas.detailList.forEach(e => {
          e.myRefundAmount = (parseFloat(e.itemPrice) + parseFloat(e.itemTax) + parseFloat(e.shippingPrice) + parseFloat(e.shippingTax)).toFixed(2)
          e.refundAmount = (parseFloat(e.myItemPrice) + parseFloat(e.myItemTax) + parseFloat(e.myShippingPrice) + parseFloat(e.myShippingTax) + parseFloat(e.myOtherRefund)).toFixed(2)
        })
        this.form = datas
        this.form.refundType = datas.detailList.at(-1)?.refundType
        this.sellerFileList = datas.imageList.filter(e => e.imageType === 0 && e.imageUrl).map(e => e.imageUrl)
        this.brcFileList = datas.imageList.filter(e => e.imageType !== 0 && e.imageUrl).map(e => e.imageUrl)
        await this.getReasonOptions(this.form.platFormName)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
